<template>
  <div>
    <v-row v-if="user" id="progressStage">
      <v-col cols="12" class="d-flex justify-lg-space-between flex-wrap">
        <TopTitle class="mb-0" :title="`${user.fullName} | ${user.nickName}`" />
        <v-btn
          color="primary"
          v-if="coursePlanId"
          @click="exportProgressStage"
          >{{ $_t("attribute.export") }}</v-btn
        >
        <TopTitle
          class="mb-0"
          v-if="lastUpdateUserFullName"
          :title="`${$_t(
            'attribute.updatedBy'
          )} ${lastUpdateUserFullName} ${$_t('attribute.at')} ${$_date(
            updateMoment,
            'long'
          )}`"
        />
      </v-col>
      <v-col cols="12">
        <ProgressStageInfo />
      </v-col>
      <v-col cols="12">
        <ProgressStageTargetOfStudy />
      </v-col>
      <v-col cols="12">
        <ProgressStageSubjectResults ref="subjectResults" />
      </v-col>
      <v-col cols="12">
        <ProgressStageLangScore ref="languageScores" />
      </v-col>
      <v-col cols="12">
        <ProgressStagePlansOfStudy ref="planingPlan" />
      </v-col>
      <v-col cols="12">
        <ProgressStagePlanning ref="curriculumPlanning" />
      </v-col>
      <v-col cols="12">
        <ProgressStageMoreInformation />
      </v-col>
      <v-col class="">
        <!--        <v-btn @click="generateReport">generate</v-btn>-->
        <!--        <v-btn @click="generateReport2">generate2</v-btn>-->
        <v-btn
          @click="save"
          :loading="isLoading"
          :disabled="isLoading"
          color="primary"
          block
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProgressStageInfo from "@/packages/admin/components/users/ProgressStageInfo";
import TopTitle from "@/components/app/TopTitle";
import ProgressStageTargetOfStudy from "@/packages/admin/components/users/ProgressStageTargetOfStudy";
import ProgressStageSubjectResults from "@/packages/admin/components/users/ProgressStageSubjectResults";
import ProgressStageLangScore from "@/packages/admin/components/users/ProgessStageLangScore";
import ProgressStagePlansOfStudy from "@/packages/admin/components/users/ProgressStagePlansOfStudy";
import { USER_INFO } from "@/packages/admin/schema/progressStage/USER_INFO.schema";
import { TARGET_OF_STUDY } from "@/packages/admin/schema/progressStage/TARGET_OF_STUDY.schema";
import ProgressStagePlanning from "@/packages/admin/components/users/ProgressStagePlanning";
import { MORE_INFO } from "@/packages/admin/schema/progressStage/MORE_INFO.schema";
import ProgressStageMoreInformation from "@/packages/admin/components/users/ProgressStageMoreInformation";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
export default {
  name: "application",
  components: {
    ProgressStageMoreInformation,
    ProgressStagePlanning,
    ProgressStagePlansOfStudy,
    ProgressStageLangScore,
    ProgressStageSubjectResults,
    ProgressStageTargetOfStudy,
    TopTitle,
    ProgressStageInfo,
  },
  async mounted() {
    await this.$actions.getUserByPublicKey(this.$route.params.id);
    await this.getStudentCoursePlan();
  },
  data() {
    return {
      userInfo: USER_INFO.model,
      targetOfStudy: TARGET_OF_STUDY.model,
      description: MORE_INFO.model,
      coursePlanId: null,
      lastUpdateUserFullName: null,
      updateMoment: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "admin/user",
      isLoading: "loading/is_loading",
    }),
  },
  methods: {
    async save() {
      await this.$actions.createCoursePlanOfStudent({
        ...this.userInfo,
        ...this.description,
        publicKey: this.user.publicKey || this.$route.params.id,
        studentsCoursePlanId: this.coursePlanId,
        targetOfStudy: this.targetOfStudy,
        subjectResults: this.$refs.subjectResults.getSubjectOfResults(),
        languageScores: this.$refs.languageScores.getLanguageScores(),
        academicPlanningPlans: this.$refs.planingPlan.getForm(),
        curriculumPlanningPlans: this.$refs.curriculumPlanning.getForm(),
      });
      await this.getStudentCoursePlan();
    },
    async getStudentCoursePlan() {
      const res = await this.$actions.getStudentCoursePLan({
        publicKey: this.user.publicKey,
      });
      if (res) {
        this.lastUpdateUserFullName = res.lastUpdateUserFullName || null;
        this.updateMoment = res.updateMoment || null;
        this.coursePlanId = res.studentsCoursePlanId;

        this.userInfo.fullName = res.fullName;
        this.userInfo.birthday = res.birthday;
        this.userInfo.school = res.school;
        this.userInfo.grade = res.grade;
        this.userInfo.abroadStudyDate = res.abroadStudyDate;
        this.userInfo.abroadGrade = res.abroadGrade;
        this.userInfo.passportID = res.passportID;
        this.userInfo.passportExpireDate = res.passportExpireDate;
        this.userInfo.level = res.level;
        this.description.description = res.description;
        Object.assign(this.targetOfStudy, res.targetOfStudy);

        this.$refs.languageScores.setLanguageScores(res.languageScores);
        if (res.subjectResults.length)
          this.$refs.subjectResults.setSubjectOfResults(res.subjectResults);
        if (res.academicPlanningPlans.length)
          this.$refs.planingPlan.setForm(res.academicPlanningPlans);
        if (res.curriculumPlanningPlans.length)
          this.$refs.curriculumPlanning.setForm(res.curriculumPlanningPlans);
      }
    },
    async exportProgressStage() {
      await api
        .get(urls.qs("exportCoursePlan", { coursePlanId: this.coursePlanId }), {
          responseType: "blob",
        })
        .then((res) => {
          FileDownload(
            res,
            `${this.user.fullName} | ${this.user.nickName} progressStage.xlsx`
          );
        });
    },
  },
  destroyed() {
    this.$store.dispatch("admin/setUser", null);
    this.coursePlanId = null;
  },
};
</script>

<style scoped></style>
